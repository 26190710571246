.content-gradient {
  position: absolute;
  top: 40vh;
  top: calc(var(--vh, 1vh) * 40);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 250;
}

.content-gradient:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  top: auto;
  height: 20vh;
  z-index: 250;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.pulse-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #1da1f2;
  cursor: pointer;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 20px #1da1f2;
}

.pulse-btn img {
  width: 60%;
}

.pulse-btn::before,
.pulse-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid #1da1f2;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none;
  animation: pulsate 2s linear infinite;
}

.pulse-btn::after {
  animation-delay: 1s;
}

.pulse-btn:hover {
  box-shadow: 0 0 20px #1da1f2;
}

.pulse-btn:hover::before,
.pulse-btn:hover::after {
  animation: pulsate 2s linear infinite;
}

.pulse-btn:hover::after {
  animation-delay: 1s;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .pulse-btn {
    box-shadow: 0 0 20px #1da1f2;
  }

  .pulse-btn::before,
  .pulse-btn::after {
    animation: pulsate 2s linear infinite;
  }

  .pulse-btn::after {
    animation-delay: 1s;
  }
}
