* {
  box-sizing: border-box;
}
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f3f9;
}
#root #__next {
  width: 100%;
  height: 100%;
}
input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
img {
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
}
